import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { AppThunk, RootState, store } from "@store/store";
import { from } from "linq-to-typescript";
import config from "@/config";
import { CodeTableState } from "@apptypes/state/code-table-state";
import { ComplaintStatusCode } from "@apptypes/code-tables/complaint-status-code";
import Option from "@apptypes/app/option";
import { generateApiParameters, get } from "@common/api";
import { DropdownOption } from "@apptypes/app/drop-down-option";
import { Agency } from "@apptypes/app/code-tables/agency";
import { Attractant } from "@apptypes/app/code-tables/attactant";
import { CODE_TABLE_TYPES } from "@constants/code-table-types";
import { NatureOfComplaint } from "@apptypes/app/code-tables/nature-of-complaint";
import { Species } from "@apptypes/app/code-tables/species";
import { Violation } from "@apptypes/app/code-tables/violation";
import { ComplaintType } from "@apptypes/app/code-tables/complaint-type";
import { Region } from "@apptypes/app/code-tables/region";
import { Zone } from "@apptypes/app/code-tables/zone";
import { Community } from "@apptypes/app/code-tables/community";
import { OrganizationCodeTable } from "@apptypes/app/code-tables/organization-code-table";
import { ReportedBy } from "@apptypes/app/code-tables/reported-by";
import { Justification } from "@apptypes/app/code-tables/justification";
import { AssessmentType } from "@apptypes/app/code-tables/assessment-type";
import { Sex } from "@apptypes/app/code-tables/sex";
import { Age } from "@apptypes/app/code-tables/age";
import { ThreatLevel } from "@apptypes/app/code-tables/threat-level";
import { ConflictHistory } from "@apptypes/app/code-tables/conflict-history";
import { EarTag } from "@apptypes/app/code-tables/ear-tag";
import { WildlifeComplaintOutcome } from "@apptypes/app/code-tables/wildlife-complaint-outcome";
import { Drug } from "@apptypes/app/code-tables/drug";
import { DrugMethod } from "@apptypes/app/code-tables/drug-method";
import { DrugRemainingOutcome } from "@apptypes/app/code-tables/drug-remaining-outcome";
import { Equipment } from "@apptypes/app/code-tables/equipment";
import { PreventionType } from "@apptypes/app/code-tables/prevention-type";
import { GirType } from "@apptypes/app/code-tables/gir-type";
import { getUserAgency } from "@service/user-service";
import {
  fetchDischargeTypes,
  fetchNonComplianceTypes,
  fetchSectorTypes,
  fetchScheduleTypes,
  fetchCEEBDecisionTypes,
  fetchScheduleSectorTypes,
} from "./code-table-thunks";
import { TeamType } from "@apptypes/app/code-tables/team";
import { CaseLocationType } from "@apptypes/app/code-tables/case-location";

const initialState: CodeTableState = {
  agency: [],
  attractant: [],
  "complaint-status": [],
  "complaint-type": [],
  "nature-of-complaint": [],
  species: [],
  violation: [],
  regions: [],
  zones: [],
  communities: [],
  "area-codes": [],
  "reported-by": [],
  justification: [],
  "assessment-type": [],
  "prevention-type": [],
  sex: [],
  age: [],
  "threat-level": [],
  "conflict-history": [],
  "ear-tag": [],
  "wildlife-outcomes": [],
  drugs: [],
  "drug-methods": [],
  "drug-remaining-outcomes": [],
  equipment: [],
  "gir-type": [],
  discharge: [],
  "non-compliance": [],
  sector: [],
  schedule: [],
  "decision-type": [],
  scheduleSector: [],
  "schedule-sector-type": [],
  team: [],
  "complaint-method-received-codes": [],
  "lead-agency": [],
  "assessment-cat1-type": [],
  "case-location-type": [],
};

export const codeTableSlice = createSlice({
  name: "code-table",
  initialState,

  reducers: {
    setCodeTable: (state: CodeTableState, action: PayloadAction<{ key: string; data: Array<any> }>) => {
      const {
        payload: { key, data },
      } = action;
      return { ...state, [key]: data };
    },
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {},
});

export const { setCodeTable } = codeTableSlice.actions;

export const fetchAllCodeTables = (): AppThunk => async (dispatch) => {
  const state = store.getState();
  const {
    codeTables: {
      "complaint-type": complaintType,
      "area-codes": areaCodes,
      "complaint-status": complaintStatus,
      attractant,
      agency,
      violation,
      species,
      "nature-of-complaint": natureOfComplaint,
      regions,
      zones,
      communities,
      "reported-by": reportedBy,
      justification,
      "assessment-type": assessmentType,
      "prevention-type": preventionAction,
      sex,
      age,
      "threat-level": threatLevels,
      "conflict-history": conflictHistory,
      "ear-tag": ears,
      "wildlife-outcomes": wildlifeOutcomes,
      drugs,
      "drug-methods": drugUseMethods,
      "drug-remaining-outcomes": remainingDrugUse,
      equipment,
      "gir-type": girType,
      discharge,
      "non-compliance": nonCompliance,
      sector,
      schedule,
      "decision-type": decisionType,
      "schedule-sector-type": scheduleSectorType,
      team,
      "complaint-method-received-codes": complaintMethodReceived,
      "lead-agency": leadAgency,
      "assessment-cat1-type": assessmentCat1Type,
      "case-location-type": caseLocationType,
    },
  } = state;

  try {
    if (!from(agency).any()) {
      dispatch(fetchAgencies());
    }

    if (!from(complaintStatus).any()) {
      dispatch(fetchComplaintStatus());
    }

    if (!from(violation).any()) {
      dispatch(fetchViolations());
    }

    if (!from(species).any()) {
      dispatch(fetchSpecies());
    }

    if (!from(natureOfComplaint).any()) {
      dispatch(fetchNatureOfComplaints());
    }

    if (!from(attractant).any()) {
      dispatch(fetchAttractants());
    }

    if (!from(regions).any()) {
      dispatch(fetchRegions());
    }

    if (!from(zones).any()) {
      dispatch(fetchZones());
    }

    if (!from(communities).any()) {
      dispatch(fetchCommunities());
    }

    if (!from(areaCodes).any()) {
      dispatch(fetchAreaCodes());
    }

    if (!from(complaintType).any()) {
      dispatch(fetchComplaintTypeCodes());
    }
    if (!from(reportedBy).any()) {
      dispatch(fetchReportedByCodes());
    }
    if (!from(justification).any()) {
      dispatch(fetchJustificationCodes());
    }
    if (!from(assessmentType).any()) {
      dispatch(fetchAssessmentTypeCodes());
    }
    if (!from(preventionAction).any()) {
      dispatch(fetchPreventionAction());
    }
    if (!from(sex).any()) {
      dispatch(fetchSexes());
    }
    if (!from(age).any()) {
      dispatch(fetchAges());
    }
    if (!from(threatLevels).any()) {
      dispatch(fetchThreatLevels());
    }
    if (!from(conflictHistory).any()) {
      dispatch(fetchConfictHistories());
    }
    if (!from(ears).any()) {
      dispatch(fetchEars());
    }
    if (!from(wildlifeOutcomes).any()) {
      dispatch(fetchWildlifeComplaintOutcomes());
    }

    if (!from(drugs).any()) {
      dispatch(fetchDrugs());
    }
    if (!from(drugUseMethods).any()) {
      dispatch(fetchDrugUseMethods());
    }
    if (!from(remainingDrugUse).any()) {
      dispatch(fetchRemainingDrugUse());
    }
    if (!from(equipment).any()) {
      dispatch(fetchEquipment());
    }
    if (!from(girType).any()) {
      dispatch(fetchGirTypes());
    }
    if (!from(discharge).any()) {
      dispatch(fetchDischargeTypes());
    }
    if (!from(nonCompliance).any()) {
      dispatch(fetchNonComplianceTypes());
    }
    if (!from(sector).any()) {
      dispatch(fetchSectorTypes());
    }
    if (!from(schedule).any()) {
      dispatch(fetchScheduleTypes());
    }
    if (!from(scheduleSectorType).any()) {
      dispatch(fetchScheduleSectorTypes());
    }
    if (!from(decisionType).any()) {
      dispatch(fetchCEEBDecisionTypes());
    }
    if (!from(team).any()) {
      dispatch(fetchTeam());
    }
    if (!from(complaintMethodReceived).any()) {
      dispatch(fetchComplaintMethodReceivedCodes());
    }
    if (!from(leadAgency).any()) {
      dispatch(fetchLeadAgencies());
    }
    if (!from(caseLocationType).any()) {
      dispatch(fetchCaseLocationTypes());
    }
    if (!from(assessmentCat1Type).any()) {
      dispatch(fetchAssessmentCat1Types());
    }
  } catch (error) {}
};

export const fetchComplaintCodeTables = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchAgencies());
    dispatch(fetchComplaintStatus());
    dispatch(fetchViolations());
    dispatch(fetchSpecies());
    dispatch(fetchNatureOfComplaints());
    dispatch(fetchAttractants());
    dispatch(fetchRegions());
    dispatch(fetchZones());
    dispatch(fetchAreaCodes());
    dispatch(fetchComplaintTypeCodes());
    dispatch(fetchReportedByCodes());
    dispatch(fetchGirTypes());
    dispatch(fetchComplaintMethodReceivedCodes());
  } catch (error) {
    console.error(error);
  }
};

export const fetchCaseCodeTables = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchJustificationCodes());
    dispatch(fetchAssessmentTypeCodes());
    dispatch(fetchPreventionAction());
    dispatch(fetchSexes());
    dispatch(fetchAges());
    dispatch(fetchThreatLevels());
    dispatch(fetchConfictHistories());
    dispatch(fetchEars());
    dispatch(fetchWildlifeComplaintOutcomes());
    dispatch(fetchDrugs());
    dispatch(fetchDrugUseMethods());
    dispatch(fetchRemainingDrugUse());
    dispatch(fetchEquipment());
    dispatch(fetchDischargeTypes());
    dispatch(fetchNonComplianceTypes());
    dispatch(fetchSectorTypes());
    dispatch(fetchScheduleTypes());
    dispatch(fetchCEEBDecisionTypes());
    dispatch(fetchScheduleSectorTypes());
    dispatch(fetchLeadAgencies());
    dispatch(fetchAssessmentCat1Types());
    dispatch(fetchCaseLocationTypes());
  } catch (error) {
    console.error(error);
  }
};

export const fetchAgencies = (): AppThunk => async (dispatch) => {
  const parameters = generateApiParameters(`${config.API_BASE_URL}/v1/code-table/${CODE_TABLE_TYPES.AGENCY}`);
  const response = await get<Array<Agency>>(dispatch, parameters);

  if (response && from(response).any()) {
    const payload = { key: CODE_TABLE_TYPES.AGENCY, data: response };
    dispatch(setCodeTable(payload));
  }
};

export const fetchAttractants = (): AppThunk => async (dispatch) => {
  const parameters = generateApiParameters(`${config.API_BASE_URL}/v1/code-table/${CODE_TABLE_TYPES.ATTRACTANT}`);
  const response = await get<Array<Attractant>>(dispatch, parameters);

  if (response && from(response).any()) {
    const payload = { key: CODE_TABLE_TYPES.ATTRACTANT, data: response };
    dispatch(setCodeTable(payload));
  }
};

export const fetchComplaintStatus = (): AppThunk => async (dispatch) => {
  const parameters = generateApiParameters(`${config.API_BASE_URL}/v1/code-table/${CODE_TABLE_TYPES.COMPLAINT_STATUS}`);
  const response = await get<Array<ComplaintStatusCode>>(dispatch, parameters);

  if (response && from(response).any()) {
    const payload = { key: CODE_TABLE_TYPES.COMPLAINT_STATUS, data: response };
    dispatch(setCodeTable(payload));
  }
};

export const fetchNatureOfComplaints = (): AppThunk => async (dispatch) => {
  const parameters = generateApiParameters(
    `${config.API_BASE_URL}/v1/code-table/${CODE_TABLE_TYPES.NATURE_OF_COMPLAINT}`,
  );
  const response = await get<Array<NatureOfComplaint>>(dispatch, parameters);

  if (response && from(response).any()) {
    const payload = {
      key: CODE_TABLE_TYPES.NATURE_OF_COMPLAINT,
      data: response,
    };
    dispatch(setCodeTable(payload));
  }
};

export const fetchSpecies = (): AppThunk => async (dispatch) => {
  const parameters = generateApiParameters(`${config.API_BASE_URL}/v1/code-table/${CODE_TABLE_TYPES.SPECIES}`);
  const response = await get<Array<Species>>(dispatch, parameters);

  if (response && from(response).any()) {
    const payload = {
      key: CODE_TABLE_TYPES.SPECIES,
      data: response,
    };
    dispatch(setCodeTable(payload));
  }
};

export const fetchViolations = (): AppThunk => async (dispatch) => {
  const parameters = generateApiParameters(`${config.API_BASE_URL}/v1/code-table/${CODE_TABLE_TYPES.VIOLATIONS}`);
  const response = await get<Array<Violation>>(dispatch, parameters);

  if (response && from(response).any()) {
    const payload = {
      key: CODE_TABLE_TYPES.VIOLATIONS,
      data: response,
    };
    dispatch(setCodeTable(payload));
  }
};

export const fetchComplaintTypeCodes = (): AppThunk => async (dispatch) => {
  const parameters = generateApiParameters(`${config.API_BASE_URL}/v1/code-table/${CODE_TABLE_TYPES.COMPLAINT_TYPE}`);
  const response = await get<Array<ComplaintType>>(dispatch, parameters);

  if (response && from(response).any()) {
    const payload = {
      key: CODE_TABLE_TYPES.COMPLAINT_TYPE,
      data: response,
    };
    dispatch(setCodeTable(payload));
  }
};

//-- these are going to need to come from the organizations
export const fetchAreaCodes = (): AppThunk => async (dispatch) => {
  const agency = "cos"; //-- TODO: when CE-212 is started this needs to be updated

  const parameters = generateApiParameters(`${config.API_BASE_URL}/v1/code-table/organization-by-agency/${agency}`);
  const response = await get<Array<OrganizationCodeTable>>(dispatch, parameters);

  if (response && from(response).any()) {
    const payload = {
      key: CODE_TABLE_TYPES.AREA_CODES,
      data: response,
    };
    dispatch(setCodeTable(payload));
  }
};

export const fetchRegions = (): AppThunk => async (dispatch) => {
  const agency = "cos"; //-- TODO: when CE-212 is started this needs to be updated

  const parameters = generateApiParameters(`${config.API_BASE_URL}/v1/code-table/regions-by-agency/${agency}`);
  const response = await get<Array<Region>>(dispatch, parameters);

  if (response && from(response).any()) {
    const payload = {
      key: CODE_TABLE_TYPES.REGIONS,
      data: response,
    };
    dispatch(setCodeTable(payload));
  }
};

export const fetchZones = (): AppThunk => async (dispatch) => {
  const agency = "cos"; //-- TODO: when CE-212 is started this needs to be updated

  const parameters = generateApiParameters(`${config.API_BASE_URL}/v1/code-table/zones-by-agency/${agency}`);
  const response = await get<Array<Zone>>(dispatch, parameters);

  if (response && from(response).any()) {
    const payload = {
      key: CODE_TABLE_TYPES.ZONES,
      data: response,
    };
    dispatch(setCodeTable(payload));
  }
};

export const fetchCommunities = (): AppThunk => async (dispatch) => {
  const agency = "cos"; //-- TODO: when CE-212 is started this needs to be updated

  const parameters = generateApiParameters(`${config.API_BASE_URL}/v1/code-table/communities-by-agency/${agency}`);
  const response = await get<Array<Community>>(dispatch, parameters);

  if (response && from(response).any()) {
    const payload = {
      key: CODE_TABLE_TYPES.COMMUNITIES,
      data: response,
    };
    dispatch(setCodeTable(payload));
  }
};

export const fetchReportedByCodes = (): AppThunk => async (dispatch) => {
  const parameters = generateApiParameters(`${config.API_BASE_URL}/v1/code-table/${CODE_TABLE_TYPES.REPORTED_BY}`);
  const response = await get<Array<ReportedBy>>(dispatch, parameters);
  if (response && from(response).any()) {
    const payload = { key: CODE_TABLE_TYPES.REPORTED_BY, data: response };
    dispatch(setCodeTable(payload));
  }
};

export const fetchJustificationCodes = (): AppThunk => async (dispatch) => {
  const parameters = generateApiParameters(
    `${config.API_BASE_URL}/v1/code-table/case-management/${CODE_TABLE_TYPES.JUSTIFICATION}`,
  );
  const response = await get<Array<Justification>>(dispatch, parameters);
  if (response && from(response).any()) {
    const payload = { key: CODE_TABLE_TYPES.JUSTIFICATION, data: response };
    dispatch(setCodeTable(payload));
  }
};

export const fetchAssessmentTypeCodes = (): AppThunk => async (dispatch) => {
  const parameters = generateApiParameters(
    `${config.API_BASE_URL}/v1/code-table/case-management/${CODE_TABLE_TYPES.ASSESSMENT_TYPE}`,
  );
  const response = await get<Array<AssessmentType>>(dispatch, parameters);
  if (response && from(response).any()) {
    const payload = { key: CODE_TABLE_TYPES.ASSESSMENT_TYPE, data: response };
    dispatch(setCodeTable(payload));
  }
};

export const fetchPreventionAction = (): AppThunk => async (dispatch) => {
  const parameters = generateApiParameters(
    `${config.API_BASE_URL}/v1/code-table/case-management/${CODE_TABLE_TYPES.PREVENTION_TYPE}`,
  );

  const response = await get<Array<PreventionType>>(dispatch, parameters);
  if (response && from(response).any()) {
    const payload = { key: CODE_TABLE_TYPES.PREVENTION_TYPE, data: response };
    dispatch(setCodeTable(payload));
  }
};

export const fetchSexes = (): AppThunk => async (dispatch) => {
  const parameters = generateApiParameters(`${config.API_BASE_URL}/v1/code-table/${CODE_TABLE_TYPES.SEX}`);

  const response = await get<Array<Sex>>(dispatch, parameters);
  if (response && from(response).any()) {
    const payload = { key: CODE_TABLE_TYPES.SEX, data: response };
    dispatch(setCodeTable(payload));
  }
};

export const fetchAges = (): AppThunk => async (dispatch) => {
  const parameters = generateApiParameters(`${config.API_BASE_URL}/v1/code-table/${CODE_TABLE_TYPES.AGE}`);

  const response = await get<Array<Age>>(dispatch, parameters);
  if (response && from(response).any()) {
    const payload = { key: CODE_TABLE_TYPES.AGE, data: response };
    dispatch(setCodeTable(payload));
  }
};

export const fetchThreatLevels = (): AppThunk => async (dispatch) => {
  const parameters = generateApiParameters(`${config.API_BASE_URL}/v1/code-table/${CODE_TABLE_TYPES.THREAT_LEVEL}`);

  const response = await get<Array<ThreatLevel>>(dispatch, parameters);
  if (response && from(response).any()) {
    const payload = { key: CODE_TABLE_TYPES.THREAT_LEVEL, data: response };
    dispatch(setCodeTable(payload));
  }
};

export const fetchConfictHistories = (): AppThunk => async (dispatch) => {
  const parameters = generateApiParameters(`${config.API_BASE_URL}/v1/code-table/${CODE_TABLE_TYPES.CONFLICT_HISTORY}`);

  const response = await get<Array<ConflictHistory>>(dispatch, parameters);
  if (response && from(response).any()) {
    const payload = { key: CODE_TABLE_TYPES.CONFLICT_HISTORY, data: response };
    dispatch(setCodeTable(payload));
  }
};

export const fetchEars = (): AppThunk => async (dispatch) => {
  const parameters = generateApiParameters(`${config.API_BASE_URL}/v1/code-table/${CODE_TABLE_TYPES.EARS}`);

  const response = await get<Array<EarTag>>(dispatch, parameters);
  if (response && from(response).any()) {
    const payload = { key: CODE_TABLE_TYPES.EARS, data: response };
    dispatch(setCodeTable(payload));
  }
};

export const fetchWildlifeComplaintOutcomes = (): AppThunk => async (dispatch) => {
  const parameters = generateApiParameters(
    `${config.API_BASE_URL}/v1/code-table/${CODE_TABLE_TYPES.WILDLIFE_OUTCOMES}`,
  );

  const response = await get<Array<WildlifeComplaintOutcome>>(dispatch, parameters);
  if (response && from(response).any()) {
    const payload = { key: CODE_TABLE_TYPES.WILDLIFE_OUTCOMES, data: response };
    dispatch(setCodeTable(payload));
  }
};

export const fetchDrugs = (): AppThunk => async (dispatch) => {
  const parameters = generateApiParameters(`${config.API_BASE_URL}/v1/code-table/${CODE_TABLE_TYPES.DRUGS}`);

  const response = await get<Array<Drug>>(dispatch, parameters);
  if (response && from(response).any()) {
    const payload = { key: CODE_TABLE_TYPES.DRUGS, data: response };
    dispatch(setCodeTable(payload));
  }
};

export const fetchDrugUseMethods = (): AppThunk => async (dispatch) => {
  const parameters = generateApiParameters(`${config.API_BASE_URL}/v1/code-table/${CODE_TABLE_TYPES.DRUG_METHOD_USE}`);

  const response = await get<Array<DrugMethod>>(dispatch, parameters);
  if (response && from(response).any()) {
    const payload = { key: CODE_TABLE_TYPES.DRUG_METHOD_USE, data: response };
    dispatch(setCodeTable(payload));
  }
};

export const fetchRemainingDrugUse = (): AppThunk => async (dispatch) => {
  const parameters = generateApiParameters(
    `${config.API_BASE_URL}/v1/code-table/${CODE_TABLE_TYPES.REMAINING_DRUG_USE}`,
  );

  const response = await get<Array<DrugRemainingOutcome>>(dispatch, parameters);
  if (response && from(response).any()) {
    const payload = { key: CODE_TABLE_TYPES.REMAINING_DRUG_USE, data: response };
    dispatch(setCodeTable(payload));
  }
};

export const fetchEquipment = (): AppThunk => async (dispatch) => {
  const parameters = generateApiParameters(`${config.API_BASE_URL}/v1/code-table/${CODE_TABLE_TYPES.EQUIPMENT}`);

  const response = await get<Array<Equipment>>(dispatch, parameters);
  if (response && from(response).any()) {
    const payload = { key: CODE_TABLE_TYPES.EQUIPMENT, data: response };
    dispatch(setCodeTable(payload));
  }
};

export const fetchGirTypes = (): AppThunk => async (dispatch) => {
  const parameters = generateApiParameters(`${config.API_BASE_URL}/v1/code-table/${CODE_TABLE_TYPES.GIR_TYPE}`);

  const response = await get<Array<GirType>>(dispatch, parameters);
  if (response && from(response).any()) {
    const payload = { key: CODE_TABLE_TYPES.GIR_TYPE, data: response };
    dispatch(setCodeTable(payload));
  }
};

export const fetchTeam = (): AppThunk => async (dispatch) => {
  const parameters = generateApiParameters(`${config.API_BASE_URL}/v1/code-table/${CODE_TABLE_TYPES.TEAM}`);
  const response = await get<Array<TeamType>>(dispatch, parameters);
  if (response && from(response).any()) {
    const payload = { key: CODE_TABLE_TYPES.TEAM, data: response };
    dispatch(setCodeTable(payload));
  }
};

export const fetchComplaintMethodReceivedCodes = (): AppThunk => async (dispatch) => {
  const agency = getUserAgency();
  const parameters = generateApiParameters(
    `${config.API_BASE_URL}/v1/code-table/complaint-method-received-by-agency/${agency}`,
  );
  const response: any = await get(dispatch, parameters);
  if (response && from(response).any()) {
    const payload = { key: CODE_TABLE_TYPES.COMPLAINT_METHOD_RECEIVED, data: response };
    dispatch(setCodeTable(payload));
  }
};

export const fetchLeadAgencies = (): AppThunk => async (dispatch) => {
  const parameters = generateApiParameters(`${config.API_BASE_URL}/v1/code-table/${CODE_TABLE_TYPES.LEAD_AGENCY}`);

  const response = await get<Array<Agency>>(dispatch, parameters);
  if (response && from(response).any()) {
    const payload = { key: CODE_TABLE_TYPES.LEAD_AGENCY, data: response };
    dispatch(setCodeTable(payload));
  }
};

export const fetchAssessmentCat1Types = (): AppThunk => async (dispatch) => {
  const parameters = generateApiParameters(
    `${config.API_BASE_URL}/v1/code-table/${CODE_TABLE_TYPES.ASSESSMENT_CAT1_TYPE}`,
  );

  const response = await get<Array<AssessmentType>>(dispatch, parameters);
  if (response && from(response).any()) {
    const payload = { key: CODE_TABLE_TYPES.ASSESSMENT_CAT1_TYPE, data: response };
    dispatch(setCodeTable(payload));
  }
};

export const fetchCaseLocationTypes = (): AppThunk => async (dispatch) => {
  const parameters = generateApiParameters(
    `${config.API_BASE_URL}/v1/code-table/${CODE_TABLE_TYPES.CASE_LOCATION_TYPE}`,
  );

  const response = await get<Array<CaseLocationType>>(dispatch, parameters);
  if (response && from(response).any()) {
    const payload = { key: CODE_TABLE_TYPES.CASE_LOCATION_TYPE, data: response };
    dispatch(setCodeTable(payload));
  }
};

export const selectCodeTable =
  (table: string) =>
  (state: RootState): Array<any> => {
    const { codeTables } = state;
    const selected = codeTables[table as keyof CodeTableState];

    return selected;
  };

export const selectComplaintTypeDropdown = (state: RootState): Array<Option> => {
  const {
    codeTables: { "complaint-type": complaintTypes },
  } = state;

  const data = complaintTypes.map(({ complaintType, longDescription }) => {
    const item: Option = { label: longDescription, value: complaintType };
    return item;
  });
  return data;
};

export const selectCreatableComplaintTypeDropdown = (state: RootState): Array<Option> => {
  const {
    codeTables: { "complaint-type": complaintTypes },
  } = state;

  // Filter out complaintType is "GIR" since it should not appear as a createable type.
  const filteredTypes = complaintTypes.filter((complaintType) => complaintType.complaintType !== "GIR");

  const data = filteredTypes.map(({ complaintType, longDescription }) => {
    const item: Option = { label: longDescription, value: complaintType };
    return item;
  });
  return data;
};

export const selectAgencyDropdown = (state: RootState): Array<Option> => {
  const {
    codeTables: { agency },
  } = state;

  const data = agency
    .filter(({ agency }) => agency !== "PARKS") //hide PARKS option for now
    .map(({ agency, longDescription }) => {
      const item: Option = { label: longDescription, value: agency };
      return item;
    });
  return data;
};

export const selectLeadAgencyDropdown = (state: RootState): Array<Option> => {
  const {
    codeTables: { "lead-agency": leadAgency },
  } = state;

  const data = leadAgency.map(({ agency, longDescription }) => {
    const item: Option = { label: longDescription, value: agency };
    return item;
  });
  return data;
};

export const selectTeamDropdown = (state: RootState): Array<Option> => {
  const {
    codeTables: { team },
  } = state;

  const data = team.map(({ team, longDescription }) => {
    const item: Option = { label: longDescription, value: team };
    return item;
  });
  return data;
};

export const selectReportedByDropdown = (state: RootState): Array<Option> => {
  const {
    codeTables: { "reported-by": reportedBy },
  } = state;

  const data = reportedBy.map(({ reportedBy, longDescription }) => {
    const item: Option = { label: longDescription, value: reportedBy };
    return item;
  });
  return data;
};

// This returns the complaint status codes, without the pending status.
export const selectComplaintStatusCodeDropdown = (state: RootState): Array<Option> => {
  const {
    codeTables: { "complaint-status": complaintStatus },
  } = state;

  // Filter out items where complaintStatus is "PENDREV" since it should only appear on the filter screen.
  const filteredStatus = complaintStatus.filter((status) => status.complaintStatus !== "PENDREV");

  const data = filteredStatus.map(({ complaintStatus, longDescription }) => {
    const item: Option = { label: longDescription, value: complaintStatus };
    return item;
  });

  return data;
};

// This returns the complaint status codes, with the pending status.
export const selectComplaintStatusWithPendingCodeDropdown = (state: RootState): Array<Option> => {
  const {
    codeTables: { "complaint-status": complaintStatus },
  } = state;

  const data = complaintStatus.map(({ complaintStatus, longDescription }) => {
    const item: Option = { label: longDescription, value: complaintStatus };
    return item;
  });
  return data;
};

export const selectSpeciesCodeDropdown = (state: RootState): Array<Option> => {
  const {
    codeTables: { species },
  } = state;

  const data = species.map(({ species, longDescription }) => {
    const item: Option = { label: longDescription, value: species };
    return item;
  });
  return data;
};

export const selectViolationCodeDropdown =
  (agency: string) =>
  (state: RootState): Array<Option> => {
    const {
      codeTables: { violation },
    } = state;

    const data = violation
      .filter(({ agencyCode }) => agencyCode === agency)
      .map(({ violation, longDescription }) => {
        const item: Option = { label: longDescription, value: violation };
        return item;
      });
    return data;
  };

export const selectGirTypeCodeDropdown = (state: RootState): Array<Option> => {
  const {
    codeTables: { "gir-type": girType },
  } = state;

  const data = girType.map(({ girType, longDescription }) => {
    const item: Option = { label: longDescription, value: girType };
    return item;
  });
  return data;
};

export const selectComplaintReceivedMethodDropdown = (state: RootState): Array<Option> => {
  const {
    codeTables: { "complaint-method-received-codes": complaintMethodReceivedType },
  } = state;

  const data = complaintMethodReceivedType.map(({ complaintMethodReceivedCode, longDescription }) => {
    const item: Option = { label: longDescription, value: complaintMethodReceivedCode };
    return item;
  });
  return data;
};

export const selectJustificationCodeDropdown = (state: RootState): Array<Option> => {
  const {
    codeTables: { justification },
  } = state;

  const data = justification.map(({ justification, longDescription }) => {
    const item: Option = { label: longDescription, value: justification };
    return item;
  });
  return data;
};

export const selectAssessmentTypeCodeDropdown = (state: RootState): Array<Option> => {
  const {
    codeTables: { "assessment-type": assessmentType },
  } = state;

  const data = assessmentType.map(({ assessmentType, longDescription }) => {
    const item: Option = { label: longDescription, value: assessmentType };
    return item;
  });
  return data;
};

export const selectYesNoCodeDropdown = (): Array<Option> => {
  const data: Option[] = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];
  return data;
};

export const selectPreventionTypeCodeDropdown = (state: RootState): Array<Option> => {
  const {
    codeTables: { "prevention-type": preventionType },
  } = state;
  const data = preventionType.map(({ preventionType, longDescription }) => {
    const item: Option = { label: longDescription, value: preventionType };
    return item;
  });
  return data;
};

export const selectHwcrNatureOfComplaintCodeDropdown = (state: RootState): Array<Option> => {
  const {
    codeTables: { "nature-of-complaint": natureOfComplaints },
  } = state;

  const data = natureOfComplaints.map(({ natureOfComplaint, longDescription }) => {
    const item: Option = { label: longDescription, value: natureOfComplaint };
    return item;
  });
  return data;
};

export const selectAreaCodeDropdown = (state: RootState): Array<Option> => {
  const {
    codeTables: { "area-codes": areaCodes },
  } = state;

  const data = areaCodes.map(({ area, areaName }) => {
    const option: Option = { value: area, label: areaName };
    return option;
  });

  return data;
};

export const selectAttractantCodeDropdown = (state: RootState): Array<Option> => {
  const {
    codeTables: { attractant },
  } = state;

  const data = attractant.map(({ attractant, shortDescription }) => {
    const item: Option = { label: shortDescription, value: attractant };
    return item;
  });

  return data;
};

export const selectZoneCodeDropdown = (state: RootState): Array<DropdownOption> => {
  const {
    codeTables: { zones },
  } = state;

  const data = zones.map(({ code, name }) => {
    const item: DropdownOption = {
      label: name,
      value: code,
      description: name,
    };
    return item;
  });
  return data;
};

export const selectRegionCodeDropdown = (state: RootState): Array<DropdownOption> => {
  const {
    codeTables: { regions },
  } = state;

  const data = regions.map(({ code, name }) => {
    const item: DropdownOption = {
      label: name,
      value: code,
      description: name,
    };
    return item;
  });
  return data;
};

export const selectCommunityCodeDropdown = (state: RootState): Array<DropdownOption> => {
  const {
    codeTables: { communities },
  } = state;

  const data = communities.map(({ code, name }) => {
    const item: DropdownOption = {
      label: name,
      value: code,
      description: name,
    };
    return item;
  });

  return data;
};

export const selectCascadedFilter =
  (type: string, value?: string) =>
  (state: RootState): Array<DropdownOption> => {
    const {
      codeTables: { regions, zones },
    } = state;

    switch (type) {
      case "region": {
        const data = regions.map(({ code, name }) => {
          const item: DropdownOption = {
            label: name,
            value: code,
            description: name,
          };
          return item;
        });

        if (value) {
          return data.filter((item) => item.value === value);
        }

        return data;
      }
      case "zone": {
        const data = zones.map(({ code, name }) => {
          const item: DropdownOption = {
            label: name,
            value: code,
            description: name,
          };
          return item;
        });

        if (value) {
          const items = zones.filter(({ region }) => region === value);
          return items.map(({ code, name }) => {
            const item: DropdownOption = {
              label: name,
              value: code,
              description: name,
            };
            return item;
          });
        }

        return data;
      }
      case "community":
      default:
        break;
    }

    return [];
  };

export const selectCascadedFilters =
  (region?: string, zone?: string, community?: string) =>
  (state: RootState): any => {
    const {
      codeTables: { regions, zones, communities },
    } = state;

    let _regions: Array<DropdownOption> = [];
    let _zones: Array<DropdownOption> = [];
    let _communities: Array<DropdownOption> = [];

    if (!region && zone && !community) {
      const selected = zones.find((item) => item.code === zone);

      if (selected) {
        _regions = regions
          .filter((item) => item.code === selected.region)
          .map(({ code, name }) => {
            return {
              label: name,
              value: code,
              description: name,
            };
          });

        _zones = [
          {
            label: selected.name,
            value: selected.code,
            description: selected.name,
          },
        ];

        _communities = communities
          .filter((item) => item.zone === zone)
          .map(({ code, name }) => {
            return {
              label: name,
              value: code,
              description: name,
            };
          });
      }
    } else if (region && !zone && !community) {
      const selected = regions.find((item) => item.code === region);
      if (selected) {
        _regions = [
          {
            label: selected.name,
            value: selected.code,
            description: selected.name,
          },
        ];

        _zones = zones
          .filter((item) => item.region === region)
          .map(({ code, name }) => {
            return {
              label: name,
              value: code,
              description: name,
            };
          });

        _communities = communities
          .filter((item) => item.region === region)
          .map(({ code, name }) => {
            return {
              label: name,
              value: code,
              description: name,
            };
          });
      }
    } else if (!region && !zone && !community) {
      _regions = regions.map(({ code, name }) => {
        return {
          label: name,
          value: code,
          description: name,
        };
      });
      _zones = zones.map(({ code, name }) => {
        return {
          label: name,
          value: code,
          description: name,
        };
      });

      _communities = communities.map(({ code, name }) => {
        return {
          label: name,
          value: code,
          description: name,
        };
      });
      return {
        regions: _regions,
        zones: _zones,
        communities: _communities,
      };
    }

    return {
      regions: _regions,
      zones: _zones,
      communities: _communities,
    };
  };

export const selectCascadedRegion =
  (region?: string, zone?: string, community?: string) =>
  (state: RootState): Array<Option> => {
    const {
      codeTables: { regions, zones, communities },
    } = state;

    let results = regions;

    if (region) {
      return regions
        .filter((item) => item.code === region)
        .map(({ code, name }) => {
          return {
            label: name,
            value: code,
            description: name,
          };
        });
    }

    if (zone) {
      const selected = zones.find((item) => item.code === zone);

      if (selected) {
        results = regions.filter((item) => item.code === selected.region);
      }
    }

    if (community) {
      const selected = communities.find((item) => item.code === community);

      if (selected) {
        results = regions.filter((item) => item.code === selected.region);
      }
    }

    return results.map(({ code, name }) => {
      return {
        label: name,
        value: code,
        description: name,
      };
    });
  };

export const selectCascadedZone =
  (region?: string, zone?: string, community?: string) =>
  (state: RootState): Array<Option> => {
    const {
      codeTables: { zones, communities },
    } = state;

    let results = zones;

    if (zone) {
      return zones
        .filter((item) => item.code === zone)
        .map(({ code, name }) => {
          return {
            label: name,
            value: code,
            description: name,
          };
        });
    }

    if (region) {
      results = zones.filter((item) => item.region === region);
    }

    if (community) {
      const selected = communities.find((item) => item.code === community);
      if (selected) {
        results = zones.filter((item) => item.code === selected.zone);
      }
    }

    return results.map(({ code, name }) => {
      return {
        label: name,
        value: code,
        description: name,
      };
    });
  };

export const selectCascadedCommunity =
  (region?: string, zone?: string, community?: string) =>
  (state: RootState): Array<Option> => {
    const {
      codeTables: { communities },
    } = state;

    if (zone) {
      return communities
        .filter((item) => item.zone === zone)
        .map(({ code, name }) => {
          return {
            label: name,
            value: code,
            description: name,
          };
        });
    }

    if (region) {
      return communities
        .filter((item) => item.region === region)
        .map(({ code, name }) => {
          return {
            label: name,
            value: code,
            description: name,
          };
        });
    }

    return communities.map(({ code, name }) => {
      return {
        label: name,
        value: code,
        description: name,
      };
    });
  };

export const selectSexDropdown = (state: RootState): Array<Option> => {
  const {
    codeTables: { sex: items },
  } = state;

  const data = items.map(({ sex: value, shortDescription: label }) => {
    const item: Option = { label, value };
    return item;
  });

  return data;
};

export const selectAgeDropdown = (state: RootState): Array<Option> => {
  const {
    codeTables: { age: items },
  } = state;

  const data = items.map(({ age: value, shortDescription: label }) => {
    const item: Option = { label, value };
    return item;
  });

  return data;
};

export const selectThreatLevelDropdown = (state: RootState): Array<Option> => {
  const {
    codeTables: { "threat-level": items },
  } = state;

  const data = items.map(({ threatLevel: value, shortDescription: label }) => {
    const item: Option = { label, value };
    return item;
  });

  return data;
};

export const selectConflictHistoryDropdown = (state: RootState): Array<Option> => {
  const {
    codeTables: { "conflict-history": items },
  } = state;

  const data = items.map(({ conflictHistory: value, shortDescription: label }) => {
    const item: Option = { label, value };
    return item;
  });

  return data;
};

export const selectEarDropdown = (state: RootState): Array<Option> => {
  const {
    codeTables: { "ear-tag": items },
  } = state;

  const data = items.map(({ earTag: value, shortDescription: label }) => {
    const item: Option = { label, value };
    return item;
  });

  return data;
};

//Used for drop downs on Create / Edit
export const selectActiveWildlifeComplaintOutcome = (state: RootState): Array<Option> => {
  const {
    codeTables: { "wildlife-outcomes": items },
  } = state;

  let filteredItems = items.filter((item) => item.isActive === true); // Only items with active_ind = true

  // Map the filtered and sorted items to the Option format
  const data = filteredItems.map(({ outcome: value, shortDescription: label }) => {
    const item: Option = { label, value };
    return item;
  });

  return data;
};

//Used for filter dropdown and View State (could be legacy data)
export const selectAllWildlifeComplaintOutcome = (state: RootState): Array<Option> => {
  const {
    codeTables: { "wildlife-outcomes": items },
  } = state;

  const data = items.map(({ outcome: value, shortDescription: label }) => {
    const item: Option = { label, value };
    return item;
  });

  return data;
};

export const selectDrugs = (state: RootState): Array<Option> => {
  const {
    codeTables: { drugs: items },
  } = state;

  const data = items.map(({ drug: value, shortDescription: label }) => {
    const item: Option = { label, value };
    return item;
  });

  return data;
};

export const selectDrugUseMethods = (state: RootState): Array<Option> => {
  const {
    codeTables: { "drug-methods": items },
  } = state;

  const data = items.map(({ method: value, shortDescription: label }) => {
    const item: Option = { label, value };
    return item;
  });

  return data;
};

export const selectRemainingDrugUse = (state: RootState): Array<Option> => {
  const {
    codeTables: { "drug-remaining-outcomes": items },
  } = state;

  const data = items.map(({ outcome: value, shortDescription: label }) => {
    const item: Option = { label, value };
    return item;
  });

  return data;
};

export const selectActiveEquipmentDropdown = createSelector(
  (state: RootState) => state.codeTables.equipment,
  (items) => {
    let filteredItems = items.filter((item) => item.isActive === true); // Only items with active_ind = true

    // Map the filtered items to the Option format
    return filteredItems.map(({ equipment: value, shortDescription: label }) => ({
      label,
      value,
    }));
  },
);

export const selectAllEquipmentDropdown = createSelector(
  (state: RootState) => state.codeTables.equipment,
  (items) => {
    // Map the filtered items to the Option format
    return items.map(({ equipment: value, shortDescription: label }) => ({
      label,
      value,
    }));
  },
);

export const selectLocationDropdown = (state: RootState): Array<Option> => {
  const {
    codeTables: { "case-location-type": items },
  } = state;

  const data = items.map(({ caseLocationType: value, shortDescription: label }) => {
    const item: Option = { label, value };
    return item;
  });

  return data;
};

export const selectAssessmentCat1Dropdown = (state: RootState): Array<Option> => {
  const {
    codeTables: { "assessment-cat1-type": items },
  } = state;

  const data = items.map(({ assessmentType: value, shortDescription: label }) => {
    const item: Option = { label, value };
    return item;
  });

  return data;
};

export const selectTrapEquipment = (state: RootState): Array<string> => {
  const {
    codeTables: { equipment: items },
  } = state;
  const data = items
    .filter(({ isTrapIndicator: value }) => value)
    .map(({ equipment: label }) => {
      const item = label;
      return item;
    });

  return data;
};

export const selectPrivacyDropdown = (): Array<Option> => {
  const data: Option[] = [
    { value: "Y", label: "Yes" },
    { value: "N", label: "No" },
  ];
  return data;
};

export default codeTableSlice.reducer;
